import queryString from "query-string";
import { RSAA } from "redux-api-middleware";

import * as types from "./reports.constants";
import {
  API_MODULES as modules,
  API_METHODS as methods,
} from "../../api.constants";

import { PorTableState } from "./reports.types";

export type FertilizerUsageReportParams = {
  from: string;
  to: string;
  type: string;
};

export type PorUsageReportParams = {
  toDate: string;
};

export const postFertilizerUsageReportApi = (
  params: FertilizerUsageReportParams,
) => ({
  [RSAA]: {
    endpoint: "reports/eagri/fertilizer-usage?",
    method: methods.POST,
    module: modules.AGROEVIDENCE,
    body: JSON.stringify(params),
    types: [
      types.POST_FERTILIZER_USAGE_REPORT_REQUEST,
      types.POST_FERTILIZER_USAGE_REPORT_SUCCESS,
      types.POST_FERTILIZER_USAGE_REPORT_ERROR,
    ],
  },
});

export const postPorUsageReportApi = (params: PorUsageReportParams) => ({
  [RSAA]: {
    endpoint: `reports/eagri/por-usage?${new URLSearchParams(params)}&`,
    method: methods.POST,
    module: modules.AGROEVIDENCE,
    types: [
      types.POST_POR_USAGE_REPORT_REQUEST,
      types.POST_POR_USAGE_REPORT_SUCCESS,
      types.POST_POR_USAGE_REPORT_ERROR,
    ],
  },
});

type ReportRequestParamsType = {
  format: "pdf" | "xlsx";
  from: string;
  id: string;
  parcelIds?: string[];
  to: string;
  reportType: string;
};

export const getReportApi = (params: ReportRequestParamsType) => {
  const { format, from, id, parcelIds, reportType, to } = params;
  const p = {
    "date-from": from,
    "date-to": to,
    "parcel-ids": parcelIds,
    "file-type": format.toUpperCase(),
  };

  return {
    [RSAA]: {
      endpoint: `reports/${reportType}?${queryString.stringify(p)}&`,
      method: methods.GET,
      module: modules.AGROEVIDENCE,
      types: [
        types.GET_REPORT_REQUEST,
        {
          type: types.GET_REPORT_SUCCESS,
          payload: (
            _action: unknown,
            _state: unknown,
            res: {
              blob: () => unknown;
              headers: { get: (h: string) => unknown };
              status: string;
            },
          ) => ({
            body: res.blob(),
            disposition: res.headers.get("Content-Disposition"),
            type: res.headers.get("Content-Type"),
            status: res.status,
            id,
          }),
        },
        types.GET_REPORT_ERROR,
      ],
    },
  };
};

export const getPorUsageReportApi = (params: PorTableState) => {
  const { page, rowsPerPage } = params;

  const urlParams = {
    page: (page + 1).toString(),
    pageSize: rowsPerPage.toString(),
    type: "POR_USE",
  };

  return {
    [RSAA]: {
      endpoint: `reports/history?${new URLSearchParams(urlParams)}&`,
      method: methods.GET,
      module: modules.AGROEVIDENCE,
      types: [
        types.GET_POR_HISTORY_REPORT_REQUEST,
        types.GET_POR_HISTORY_REPORT_SUCCESS,
        types.GET_POR_HISTORY_REPORT_ERROR,
      ],
    },
  };
};

export const getFarmNitrogenReportApi = (
  from: string,
  format: "pdf" | "xlsx",
) => {
  const p = {
    "date-from": from,
    "file-type": format.toUpperCase(),
  };

  return {
    [RSAA]: {
      endpoint: `reports/farm-nitrogen?${queryString.stringify(p)}&`,
      method: methods.GET,
      module: modules.AGROEVIDENCE,
      types: [
        types.GET_FARM_NITROGEN_REPORT_REQUEST,
        {
          type: types.GET_FARM_NITROGEN_REPORT_SUCCESS,
          payload: (action: unknown, state: unknown, res: Response) => ({
            body: res.blob(),
            disposition: res.headers.get("Content-Disposition"),
            type: res.headers.get("Content-Type"),
            status: res.status,
            id: "farmNitrogen",
          }),
        },
        types.GET_FARM_NITROGEN_REPORT_ERROR,
      ],
    },
  };
};

export const getSowingPlanReportApi = () => ({
  [RSAA]: {
    endpoint: `reports/eagri/sowing-plan?`,
    method: methods.GET,
    module: modules.AGROEVIDENCE,
    types: [
      types.GET_SOWING_PLAN_REQUEST,
      {
        type: types.GET_SOWING_PLAN_SUCCESS,
        payload: (action: unknown, state: unknown, res: Response) => ({
          body: res.blob(),
          disposition: res.headers.get("Content-Disposition"),
          type: res.headers.get("Content-Type"),
          status: res.status,
          id: "sowingPlan",
        }),
      },
      types.GET_SOWING_PLAN_ERROR,
    ],
  },
});

export const getCropRotationReportApi = (date: string) => {
  const urlParams = {
    date,
  };

  return {
    [RSAA]: {
      endpoint: `reports/restrictions/sowing?${new URLSearchParams(urlParams)}&`,
      method: methods.GET,
      module: modules.AGROEVIDENCE,
      types: [
        types.GET_CROP_ROTATION_REPORT_REQUEST,
        types.GET_CROP_ROTATION_REPORT_SUCCESS,
        types.GET_CROP_ROTATION_REPORT_ERROR,
      ],
    },
  };
};
