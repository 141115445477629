import React from "react";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Link,
  Theme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import _ from "lodash";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";

import {
  ValidationResponseTo,
  ValidationResultTo,
} from "../../../../generated/api/agroevidence";

interface Props {
  onClose: () => void;
  showDialog: boolean;
  cropRotationReport: ValidationResponseTo;
}
const CropRotationResultDialog = ({
  cropRotationReport,
  onClose,
  showDialog,
}: Props) => {
  const classes = useStyles();
  const { farmId } = useParams<{ farmId: string }>();

  const eagriCodeRestrictions: ValidationResultTo | undefined =
    cropRotationReport.restrictions.find(
      (r) => r.code === "EagriCodeRestrictions",
    );

  const controlledViolations =
    eagriCodeRestrictions?.violations.filter(
      (v) =>
        v.severity === "ERROR" &&
        (v.code === "O601" || v.code === "O513" || v.code === "O514"),
    ) || [];

  const uniqueData = controlledViolations.filter(
    (value, index, self) =>
      index === self.findIndex((t) => _.isEqual(t, value)),
  );

  const groupedUniqueData = _.groupBy(uniqueData, "code");

  return (
    <Dialog
      data-test="report-crop-rotation-dialog"
      maxWidth="md"
      onClose={onClose}
      open={showDialog}
    >
      <DialogTitle>
        <FormattedMessage id="Reports.cropRotation.heading" />
      </DialogTitle>
      <DialogContent>
        {Object.entries(groupedUniqueData).map(([code, items], codeIndex) => (
          <Grid key={codeIndex}>
            <Grid className={classes.restrictionCode}>
              <FormattedMessage
                id="Reports.cropRotation.codeRestrictionViolated"
                values={{
                  code,
                }}
              />
            </Grid>
            <Grid className={classes.itemsContainer} key={codeIndex}>
              {items.map((item, index) => (
                <Grid
                  className={classes.content}
                  container
                  data-test="restrictions-content"
                  key={`${code}-${index}`}
                >
                  <Grid item xs={3}>
                    <Link
                      href={`/farm/${farmId}/parcels/${item.parcelId}/overview`}
                      target="_blank"
                      underline="hover"
                    >
                      {item.parcel?.name}
                    </Link>
                  </Grid>
                  <Grid item xs={9}>
                    <div
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{ __html: item.text }}
                    />
                  </Grid>
                </Grid>
              ))}
            </Grid>
            {codeIndex < Object.keys(groupedUniqueData).length - 1 && (
              <Divider className={classes.content} variant="fullWidth" />
            )}
          </Grid>
        ))}
      </DialogContent>
    </Dialog>
  );
};

export { CropRotationResultDialog };

const useStyles = makeStyles((theme: Theme) => ({
  itemsContainer: {
    padding: "5px 15px",
  },
  restrictionCode: {
    margin: "20px 0px",
    fontWeight: 500,
    fontSize: 16,
    color: theme.palette.secondary.dark,
  },
  content: {
    marginBottom: 20,
  },
}));
