import { ReportsState } from "../../../../reducers/reports.reducer.types";

export const getErrorInfo = (state: ReportsState) =>
  state.api.reports.errorFertilizerUsageReport?.error;
export const getErrorDetailedMessage = (state: ReportsState) =>
  state.api.reports.errorFertilizerUsageReport?.detailedMessage;
export const getIsFertilizerUsageReport = (state: ReportsState) =>
  state.api.reports.isFertilizerUsageReport;

export const getPorErrorInfo = (state: ReportsState) =>
  state.api.reports.errorPorUsageReport?.error;
export const getPorErrorDetailMessage = (state: ReportsState) =>
  state.api.reports.errorPorUsageReport?.detailedMessage;
export const getIsPorUsageReportLoading = (state: ReportsState) =>
  state.api.reports.isPorUsageReportLoading;

export const getPorHistoryReport = (state: ReportsState) =>
  state.api.reports.porHistoryReport;
export const getTotalCount = (state: ReportsState) =>
  state.api.reports.porTotalCount;
export const getIsPorReportHistoryIsLoading = (state: ReportsState) =>
  state.api.reports.isPorUsageReportLoading;

export const getCropRotationReportReport = (state: ReportsState) =>
  state.api.reports.cropRotationReport;

export const getIsFetchingReport = (state: ReportsState) =>
  state.api.reports.isFetchingReport;
