const PREFIX = "AGROEVIDENCE_REPORTS";

export const GET_POR_HISTORY_REPORT_SUCCESS = `${PREFIX}/GET_POR_HISTORY_REPORT_SUCCESS`;
export const GET_POR_HISTORY_REPORT_REQUEST = `${PREFIX}/GET_POR_HISTORY_REPORT_REQUEST`;
export const GET_POR_HISTORY_REPORT_ERROR = `${PREFIX}/GET_POR_HISTORY_REPORT_ERROR`;

export const GET_REPORT_REQUEST = `${PREFIX}/GET_REPORT_REQUEST`;
export const GET_REPORT_SUCCESS = `${PREFIX}/GET_REPORT_SUCCESS`;
export const GET_REPORT_ERROR = `${PREFIX}/GET_REPORT_ERROR`;

export const GET_FARM_NITROGEN_REPORT_REQUEST = `${PREFIX}/GET_FARM_NITROGEN_REPORT_REQUEST`;
export const GET_FARM_NITROGEN_REPORT_SUCCESS = `${PREFIX}/GET_FARM_NITROGEN_REPORT_SUCCESS`;
export const GET_FARM_NITROGEN_REPORT_ERROR = `${PREFIX}/GET_FARM_NITROGEN_REPORT_ERROR`;

const PREFIX_EAGRI = "AGROEVIDENCE_REPORTS_EAGRI";

export const POST_FERTILIZER_USAGE_REPORT_REQUEST = `${PREFIX_EAGRI}/POST_FERTILIZER_USAGE_REPORT_REQUEST `;
export const POST_FERTILIZER_USAGE_REPORT_SUCCESS = `${PREFIX_EAGRI}/POST_FERTILIZER_USAGE_REPORT_SUCCESS`;
export const POST_FERTILIZER_USAGE_REPORT_ERROR = `${PREFIX_EAGRI}/POST_FERTILIZER_USAGE_REPORT_ERROR`;

export const POST_POR_USAGE_REPORT_REQUEST = `${PREFIX_EAGRI}/POST_POR_USGAE_REPORT_REQUEST`;
export const POST_POR_USAGE_REPORT_SUCCESS = `${PREFIX_EAGRI}/POST_POR_USGAE_REPORT_SUCCESS`;
export const POST_POR_USAGE_REPORT_ERROR = `${PREFIX_EAGRI}/POST_POR_USGAE_REPORT_ERROR`;

export const GET_SOWING_PLAN_REQUEST = `${PREFIX_EAGRI}/GET_SOWING_PLAN_REQUEST`;
export const GET_SOWING_PLAN_SUCCESS = `${PREFIX_EAGRI}/GET_SOWING_PLAN_SUCCESS`;
export const GET_SOWING_PLAN_ERROR = `${PREFIX_EAGRI}/GET_SOWING_PLAN_ERROR`;

export const GET_CROP_ROTATION_REPORT_REQUEST = `${PREFIX_EAGRI}/GET_CROP_ROTATION_REPORT_REQUEST`;
export const GET_CROP_ROTATION_REPORT_SUCCESS = `${PREFIX_EAGRI}/GET_CROP_ROTATION_REPORT_SUCCESS`;
export const GET_CROP_ROTATION_REPORT_ERROR = `${PREFIX_EAGRI}/GET_CROP_ROTATION_REPORT_ERROR`;
