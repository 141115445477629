import React from "react";

import { Grid, Button, CircularProgress, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import {
  getIsFetchingReport,
  getCropRotationReportReport,
} from "../../../../shared/api/agroevidence/reports/reports.selectors";

import { getCropRotationReportApi } from "../../../../shared/api/agroevidence/reports/reports.api";
import PageHeading from "../../../../shared/components/common/PageHeading/PageHeading";
import { useToggle } from "../../../../shared/hooks/useToggle";
import { getShortDateString } from "../../../../shared/misc/timeHelpers";

import { CropRotationResultDialog } from "./CropRotationResultDialog";

const CropRotationReport = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const cropRotationReport = useSelector(getCropRotationReportReport);
  const isFetchingReports = useSelector(getIsFetchingReport);

  const {
    on: showDialog,
    setOff: handleDialogClose,
    setOn: handleDialogOpen,
  } = useToggle();

  const onRestrictionsSowingReport = () => {
    dispatch(getCropRotationReportApi(getShortDateString(moment())));
  };

  return (
    <>
      {cropRotationReport && (
        <CropRotationResultDialog
          cropRotationReport={cropRotationReport}
          onClose={handleDialogClose}
          showDialog={showDialog}
        />
      )}
      <span className={classes.heading}>
        <PageHeading
          dataTest="report=csv-heading"
          translationId="Reports.cropRotation.heading"
        />
      </span>
      <Grid className={classes.info} container justifyContent="center">
        <Grid item sm={2} xs={1} />
        <Grid item sm={8} xs={12}>
          <FormattedMessage id="Reports.cropRotation.info" />
        </Grid>
        <Grid item sm={2} xs={1} />
      </Grid>
      <div className={classes.button}>
        <Button
          color="primary"
          disabled={isFetchingReports}
          id="crop-rotation-report"
          onClick={onRestrictionsSowingReport}
          variant="contained"
        >
          <FormattedMessage id="Reports.cropRotation.button" />
          {isFetchingReports && (
            <CircularProgress className={classes.progress} size={21} />
          )}
        </Button>
        {cropRotationReport && (
          <Button
            className={classes.resultButton}
            color="primary"
            onClick={handleDialogOpen}
            variant="contained"
          >
            <FormattedMessage id="Reports.cropRotation.resultButton" />
          </Button>
        )}
      </div>
    </>
  );
};

export { CropRotationReport };

const useStyles = makeStyles((theme: Theme) => ({
  heading: {
    display: "flex",
    justifyContent: "center",
    marginTop: 30,
    marginBottom: 10,
    fontSize: 24,
    fontWeight: 500,
  },
  info: {
    fontSize: 13,
    color: theme.palette.grey[500],
    marginBottom: 20,
    textAlign: "center",
  },
  button: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 30,
  },
  resultButton: {
    marginLeft: 30,
  },
  progress: {
    marginLeft: 10,
  },
}));
