import React, { useContext } from "react";

import { IconButton, Tooltip } from "@mui/material";
import TableRowMUI from "@mui/material/TableRow";
import { makeStyles } from "@mui/styles";
import { useQueryClient } from "@tanstack/react-query";
import classNames from "classnames";
import { isNil } from "lodash";
import { FormattedMessage } from "react-intl";
import { ConnectedProps, connect } from "react-redux";
import { useHistory } from "react-router-dom";

import { getPrecisionParcelListFocusedRow } from "../../../../shared/api/sentinel/precision/precision.selectors";

import { PRECISION_URLS } from "../../../../core/precision/precision.constants";

import deleteIcon from "../../../../assets/img/icons/satellite/precision/services/delete.svg";
import {
  DashboardParcelTo,
  InternalCropTo,
  SeasonTo,
} from "../../../../generated/api/satellite";
import CfFormattedNumber from "../../../../shared/components/common/CfFormattedNumber/CfFormattedNumber";
import { SelectObject } from "../../../../shared/components/form/FormSelect/types";
import CfTableCell from "../../../../shared/components/tables/CfTableCell/CfTableCell";
import { SnackbarContext } from "../../../../shared/containers/SnackbarProvider/SnackbarProvider";
import { useFocusedTableRowTarget } from "../../../../shared/hooks/useFocusedTableRowTarget";
import { useToggle } from "../../../../shared/hooks/useToggle";
import { ActiveProductsCell } from "../components/ActiveProductsCell";

import { CropCell } from "../components/CropCell";
import { PRECISION_TABS } from "../../../../core/precision/containers/PrecisionDetail";
import { DeleteDialog } from "../components/DeleteDialog";
import {
  satelliteServicesKey,
  useServicesDelete,
} from "../PrecisionServices.api";

import { PrecisionState } from "../../../../reducers/precision.reducer.types";

type ReduxProps = ConnectedProps<typeof connector>;
type OwnProps = {
  farmId: string;
  isInit?: boolean;
  parcel: DashboardParcelTo;
  season?: SeasonTo;
  cropsOptions: SelectObject<InternalCropTo>[];
};
type Props = ReduxProps & OwnProps;

const mapStateToProps = (state: PrecisionState) => ({
  focusedRow: getPrecisionParcelListFocusedRow(state),
});

const TableRow = ({
  cropsOptions,
  farmId,
  focusedRow,
  isInit,
  parcel,
  season,
}: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const queryClient = useQueryClient();
  const showSnackbar = useContext(SnackbarContext);

  const { targetElementRef } = useFocusedTableRowTarget({
    focusedRow,
    isInit,
    rowId: parcel.parcelId.toString(),
  });

  const {
    on: deleteIsOpen,
    setOff: handleDeleteClose,
    setOn: handleDeleteOpen,
  } = useToggle();

  const servicesMutation = useServicesDelete();

  const handleDeleteAccept = () => {
    servicesMutation.mutate(
      [season?.id ?? "", parcel.parcelId, { farmIds: farmId }],
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: satelliteServicesKey,
          });
          showSnackbar({
            message: (
              <FormattedMessage id="PrecisionServices.deleteDialog.submitted" />
            ),
            isSuccess: true,
          });
          handleDeleteClose();
        },
        onError: () => {
          showSnackbar({
            message: <FormattedMessage id="common.changesSaved.error" />,
            isError: true,
          });
        },
      },
    );
  };

  const handleRowClick = (parcel: DashboardParcelTo) => {
    const planetIsActive = parcel?.products?.includes(
      "SENTINEL_HUB_MANAGEMENT_ZONES_SAVI",
    );
    const soilIsActive = parcel?.products?.includes("INTERNAL_SOIL_SAMPLES");
    const monitoringIsActive =
      parcel?.products?.includes("PLANET_BIOMONITORING_SAVI") ||
      parcel?.products?.includes("GISAT_BIOPHYSICAL_MONITORING");
    const yieldIsActive = !!parcel?.yieldInTonnes;

    let tabPath;
    if (planetIsActive) {
      tabPath = PRECISION_TABS.zoneManagement.path;
    } else if (monitoringIsActive) {
      tabPath = PRECISION_TABS.monitoring.path;
    } else if (soilIsActive) {
      tabPath = PRECISION_TABS.soilSamples.path;
    } else if (yieldIsActive) {
      tabPath = PRECISION_TABS.yieldMaps.path;
    }

    history.push(
      `/farm/${farmId}/${PRECISION_URLS.services()}/${parcel.externalParcelId}/${tabPath}`,
    );
  };

  return (
    <>
      <TableRowMUI
        className={classes.pointer}
        hover
        onClick={() => handleRowClick(parcel)}
        ref={targetElementRef}
      >
        <CfTableCell name="name">
          <span className={classes.name}>{parcel.localName}</span>
        </CfTableCell>
        <CfTableCell classes={{ root: classes.column }} name="blockNr">
          <span>{parcel.blockNr}</span>
        </CfTableCell>
        <CfTableCell classes={{ root: classes.column }} name="area">
          <span>
            <CfFormattedNumber value={parcel.areaInHa || 0} /> ha
          </span>
        </CfTableCell>
        <CfTableCell classes={{ root: classes.column }} name="crop">
          <CropCell
            cropsOptions={cropsOptions}
            parcel={parcel}
            season={season}
          />
        </CfTableCell>
        <CfTableCell classes={{ root: classes.column }} name="yield">
          {isNil(parcel.yieldInTonnes) ? (
            "-"
          ) : (
            <span>{parcel.yieldInTonnes} ha</span>
          )}
        </CfTableCell>
        <CfTableCell
          classes={{ root: classNames(classes.column, classes.services) }}
          name="services"
        >
          <ActiveProductsCell
            farmId={farmId}
            parcel={parcel}
            products={parcel.products ?? []}
          />
        </CfTableCell>
        <CfTableCell
          classes={{ root: classNames(classes.column, classes.actions) }}
          name="actions"
        >
          <Tooltip title={<FormattedMessage id="common.delete" />}>
            <IconButton
              onClick={handleDeleteOpen}
              onMouseDown={handleDeleteOpen}
              style={{ padding: 9 }}
            >
              <img alt="delete" src={deleteIcon} />
            </IconButton>
          </Tooltip>
        </CfTableCell>
      </TableRowMUI>
      {deleteIsOpen && (
        <DeleteDialog
          handleAccept={handleDeleteAccept}
          handleClose={handleDeleteClose}
          open={deleteIsOpen}
          parcel={parcel}
          season={season}
        />
      )}
    </>
  );
};

const useStyles = makeStyles(() => ({
  services: {
    padding: "6px 0",
  },
  actions: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "6px 0",
  },
  name: {
    paddingLeft: 15,
    fontSize: 16,
    fontWeight: 700,
    color: "#00A179",
  },
  pointer: {
    cursor: "pointer",
  },
  column: {
    textAlign: "left",
    fontSize: 16,
  },
}));

const connector = connect(mapStateToProps);
export default connector(TableRow);
